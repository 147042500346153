import { useEffect, useState, useRef } from "react";
import { get, update } from "firebase/database";
import { TextField } from "@mui/material";
import * as ROUTES from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { useStore } from "react-redux";
import { useFirebase } from "../../../app/firebase/context";
import { Semaforito } from "app/components/Semaforito/Semaforito";
import React from "react";
import Grid from "@mui/material/Grid";
import "font-awesome/css/font-awesome.min.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BackButton from "app/components/helpers/BackButton";
import { RefreshButton } from "app/components/helpers/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ExportToCSV, ExportToPDF } from "app/library/ExportCsvPdf";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { PiArrowElbowLeftDownBold } from "react-icons/pi";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Image } from "react-bootstrap";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import { Breakpoint } from "react-socks";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import { List, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { DropzoneArea } from "material-ui-dropzone";
import obtenerIconoPorExtension from "app/components/helpers/IconosFicheros";
import { ref, uploadBytes, getDownloadURL, updateMetadata } from "firebase/storage";
import "app/views/juntas/formularioqr.css";
import {
  getSupportedFileTypes,
  getUploadLimits,
  truncarCadena,
  handleDescargar,
} from "../documentos/funcionesDocs";

import {
  traerActividades,
  traerProveedores,
  traerUsuariosRegistradores,
  getListaEstados,
  getListaTipos,
  traerComunidadesConActivGremios,
  traerDocumentosIncidencias,
  getEstadoIncidencia,
} from "../incidencias/FuncionesIncidencias";

import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import daysToWeeks from "date-fns/daysToWeeks/index.js";
const FormularioPublicoIncidencias = () => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  let [adminUsersList, setAdminUsersList] = useState([]);
  let [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
  let [numRtdos, setNumRtdos] = useState([]);
  const datosIniciales = useRef(adminUsersList);
  const listaFiltros = ["Nombre", "Id", "Cif", "Direccion"];
  const [loading, setLoading] = useState(true);
  const { parametros } = useParams();
  const [carpetaIncidencia, setCarpetaIncidencia] = useState(null);

  const firebase = useFirebase();

  const navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
    error: null,
    usuarios: [],
    entrar: true,
    open: false,
    Estado: "",
    Fecha_Modificacion: "",
    Codigo: "",
    Comunidad: "",
    Tipo: "",
    Actividad: "",
    Proveedor: "",
    NombreRegistrador: "",
    ApellidoRegistrador: "",
    Texto_publicar: "",
    Descripcion: "",
    Notas: "",
    Publicar: "",
    Email: "",
    Email2: "",
    Telefono1: "",
    Telefono2: "",
    ImagenUrl: "",
    Texto_añadir: "",
    Open: false,
    IdInterno: "",
    Pdfs: [],
    Registro: null,
    estado_inicial: "",
  };
  const [state, setState] = useState(initialState);
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const fileTypes = getSupportedFileTypes();
  const uploadLimits = getUploadLimits();
  const maxArchivos = uploadLimits.maxArchivos;
  const maxFileSizeMB = uploadLimits.maxFileSizeMB;

  const getListaDocumentos = async (carpeta) => {
    let listaDocumentosIncidencia = null;
    if (carpeta) {
      listaDocumentosIncidencia = await firebase.getFolderContents(carpeta);
      //const doccs = [];
      listaDocumentosIncidencia.map(async (docu) => {
        const url = (docu.urlDownload = await getDownloadURL(ref(firebase.storage, docu.id)));
      });
    }
    return listaDocumentosIncidencia;
  };

  const handleDrop = async (files) => {
    try {
      //const carpetaIncidencia = `incidencias/${IdComu}/${IdIncidencia}`;
      const metadata = { customMetadata: { propietario: "gremio" } };
      if (files.length > maxArchivos) {
        throw diccionario.CambioEstadoIncidenciaCodigo.replace("${var1}", maxArchivos);
      }

      const uploadPromises = [];
      let totalSize = 0;

      let archivos = [];

      for (const file of files) {
        // Check file type
        if (!fileTypes.includes(file.type)) {
          throw diccionario.TipoArchivoNoPermitido.replace("${var1}", file.type);
        }

        // Check file size
        if (file.size > maxFileSizeMB * 1024 * 1024) {
          throw diccionario.ArchivoTamañoMaximoSuperado.replace("${var1}", file.name).replace(
            "$´{var2}",
            maxFileSizeMB
          );
        }

        totalSize += file.size;

        const archivoIncidencia = `${carpetaIncidencia}/${file.name}`;

        const fileRef = ref(firebase.storage, archivoIncidencia);

        const uploadPromise = uploadBytes(fileRef, file).then((snapshot) => {
          updateMetadata(fileRef, metadata)
            .then((meta) => {
              //console.log("metadata", meta);
            })
            .catch((error) => {
              alert(diccionario.ErrorCambiarTransferibilidad + error);
            });
        });
        archivos.push(file.name);
        uploadPromises.push(uploadPromise);
      }

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      const docusInci = await getListaDocumentos(carpetaIncidencia);
      setState({
        ...state,
        Pdfs: docusInci,
      });
    } catch (error) {
      toast.error(error, getCustomToastOptions(toast, "error"));
    }
  };

  const onDrop = (file) => {
    const Pdfs = Pdfs;
    const registro = Registro;
    //this.setState({ open: true });

    const inci = this.state.inci;
    const metadata = { customMetadata: { propietario: "gremio" } };

    const putData = async () => {
      return Promise.all(
        file.map((fichero) => {
          return new Promise((resolve) => {
            if (Pdfs && Pdfs.length > 0) {
              const pdf = Pdfs.filter((file) => file.Nombre === fichero.name);
              if (pdf && pdf.length > 0) {
                if (!pdf[0].meta) {
                  alert(diccionario.ElFicheroExisteNoModificar.replace("${var1}", fichero.name));
                  resolve(false);
                }
              } else {
                this.state.firebase
                  .storage_propio_completo(
                    "incidencias/" + Comunidad + "/" + Codigo + "/" + fichero.name
                  )
                  .put(fichero, metadata)
                  .then((data) => {
                    this.state.firebase.inci(registro.inci_key + "/").update({ hayFichero: true });
                    this.state.firebase
                      .storage_propio_completo(
                        "incidencias/" + Comunidad + "/" + Codigo + "/" + fichero.name
                      )
                      .getDownloadURL()
                      .then((url) => {
                        resolve(url);
                      });
                  });
              }
            } else {
              const path = "incidencias/" + Comunidad + "/" + Codigo + "/" + fichero.name;
              //       const pathResul='incidencias/'+inci.Comunidad+"/"+inci.Codigo
              this.state.firebase
                .storage_propio_completo(path)
                .put(fichero, metadata)
                .then(() => {
                  this.state.firebase.inci(registro.inci_key + "/").update({ hayFichero: true });

                  this.state.firebase
                    .storage_propio_completo(path)
                    .getDownloadURL()
                    .then((url) => {
                      resolve(url);
                    });
                  // const dato = data.metadata
                });
            }
          });
        })
      );
    };

    putData().then((urls) => {
      /*

      urls = urls.filter((url) => url != false);
      this.state.firebase.inci(inci.uid).once("value", (snapshot) => {
        let incidencia = snapshot.val();
        if (incidencia) {
          var ImagenesUrl =
            incidencia.ImagenesUrl && incidencia.ImagenesUrl.length > 0
              ? urls.concat(incidencia.ImagenesUrl)
              : urls;
          incidencia.ImagenesUrl = ImagenesUrl;
          incidencia.Imagen = "SI";
          incidencia.Fecha_Modificacion = moment(new Date()).format(
            "DD-MM-YYYY HH:mm"
          );
          this.state.firebase.inci(inci.uid).update(incidencia);
        }
      });

      this.buscarficheros(
        "incidencias/" + inci.Comunidad + "/" + inci.Codigo + "/"
      );
      //  this.setState({open:false})
      */
    });

    // }
  };

  const onSubmitburnno = async (bd) => {
    var despacho = [];
    var firebase2 = "";
    
    const email = process.env.REACT_APP_PUBLIC_USER;
    const password = process.env.REACT_APP_PUBLIC_PASS;

    const auth = getAuth();
    const response = await signInWithEmailAndPassword(auth, email, password);
    var { user } = response;

    const customClaims = await user.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims;
    });
    const connectionInfo = customClaims.connectionInfo ?? null;

    const lengu = store.getState().lenguajes;
    const idiomaSeleccionadoState = lengu.lenguaje;

    // Rescato bd admin
    const bddAdm = process.env.REACT_APP_DATABASE_ADMIN_URL;
    await firebase.changeDatabase(bddAdm);
    let urldb = await get(firebase.getReferencia(`desks/${bd}/url`));
    urldb = urldb.val();

    await firebase.changeDatabase(urldb);
    const id = urldb.split("conecta-c365-")[1].split(".")[0];
    await firebase.changeStorage(`gs://conecta-c365-${id}`);
  };

  const [listaEstados, setListaEstados] = useState([]);
  const [listaEstadosSelected, setListaEstadosSelected] = useState([]);

  const handleClickBorrarFichero = () => {};

  const actualizar = async () => {
    if (!window.confirm(diccionario.EnviarCambiosRealizados)) {
      return true;
    }
    if (Estado === "CERRADA") {
      if (!window.confirm(diccionario.CambiarEstadoCerradaNoModificar)) {
        return true;
      }
    }
    setState({
      ...initialState,
      Open: true,
    });

    // Actualizar el estado con la información obtenida

    let fecha = moment(new Date()).format("DD-MM-YYYY HH:mm");

    const descripcion_final = "\n" + "<" + Proveedor + "> " + fecha + " " + Texto_añadir;

    const Texto_pub = Texto_publicar + descripcion_final;
    const Desc = Descripcion + descripcion_final;

    const updatedData = {
      inciLeida: [],
      Estado: Estado,
      Texto_publicar: Texto_pub,
      Descripcion: Desc,
      Fecha_Modificacion: fecha,
    };

    const incidenciaUpdatear = firebase.getReferencia(`Incidencias/${IdInterno}`);

    const newRef = await update(incidenciaUpdatear, updatedData);

    setState({
      ...state,
      Texto_añadir: "",
      estado_inicial: Estado === "CERRADA" ? true : false,
      Open: false,
    });

    alert(diccionario.IncidenciaActualizadaCorrectamente);

    /*
    toast.success(
      diccionario.DatosGuardadosCorrectamente,
      getCustomToastOptions(toast)
    );
        setState({
      ...initialState,
      Open: false,
    });

  */

    /*
    this.setState({ open: true });
    const registro = this.state.registro;
    let inci = this.state.inci;
    inci.Estado = this.state.estado;
    inci.inciLeida = [];

    let fecha = moment(new Date()).format("DD-MM-YYYY HH:mm");
    inci.Fecha_Modificacion = fecha;
    const descripcion_final =
      "\n" +
      "<" +
      inci.Proveedor +
      "> " +
      fecha +
      " " +
      this.state.Texto_añadir;

    inci.Texto_publicar = inci.Texto_publicar + descripcion_final;
    inci.Descripcion = inci.Descripcion + descripcion_final;
    this.state.firebase
      .inci(registro.inci_key)
      .update(inci)
      .then(() => {
        this.setState({
          estado_inicial: inci.Estado === "CERRADA" ? true : false,
        });
        this.setState({ inci: inci, Texto_añadir: "", open: false }, () => {
          alert("La incidencia se ha actualizado correctamente");
        });
      });
      */
  };

  const {
    IdInterno,
    Codigo,
    Comunidad,
    Estado,
    Tipo,
    Actividad,
    Proveedor,
    NombreRegistrador,
    ApellidoRegistrador,
    Texto_publicar,
    Descripcion,
    HistoricoDescripcion,
    actualizarDescripcion,
    Notas,
    Publicar,
    Email,
    Email2,
    Telefono1,
    Telefono2,
    ImagenUrl,
    Fecha_Modificacion,
    Texto_añadir,
    Open,
    Pdfs,
    Registro,
    estado_inicial,
  } = state;

  useEffect(async () => {
    //try {
    const idParam = parametros.split("*");

    const inci_codigo0 = idParam[1].toString();
    const inci_key0 = idParam[5].toString();

    const inci_codigo = inci_codigo0.substring(4);
    //const inci_key = inci_key0.substring(8, inci_key0.length - 8);
    let inci_key = inci_key0.slice(0, -8);
    inci_key = inci_key.slice(8);

    const basedatos0 = idParam[3].toString();
    const basedatos = basedatos0.substring(2);

    const registro = {
      inci_codigo: inci_codigo,
      inci_key: inci_key,
      basedatos: basedatos,
    };
    await onSubmitburnno(basedatos);

    const listEstados = getListaEstados(diccionario);
    //  Todos menos cancelada
    setListaEstados(listEstados.filter((est) => est.value !== "CANCELADA"));

    const myRef = firebase.getReferencia(`Incidencias/${inci_key}`);

    let datos = [];
    const snapshot = await get(myRef);
    datos = snapshot.val();

    // Si no existe muestro mensaje de error y salgo
    if (!datos) {
      estado_inicial = true;
      alert(diccionario.IncidenciaNoExiste);
    }

    const comuRef = firebase.getReferencia(`Comunidad/${datos.Comunidad}`);
    const snapshotComu = await get(comuRef);
    const datosComu = snapshotComu.val();

    setComunidadesSeleccionadas([datosComu]);

    setCarpetaIncidencia(`incidencias/${datos.Comunidad}/${inci_codigo}`);

    var estado_inicial = false;
    if (datos.Estado === "CERRADA") {
      estado_inicial = true;
      alert(diccionario.IncidenciaCerradaCambioAdministrador);
    }
    if (datos.Estado === "CANCELADA") {
      estado_inicial = true;
      alert(diccionario.IncidenciaCanceladaCambioAdministrador);
    }

    const docusInci = await getListaDocumentos(`incidencias/${datos.Comunidad}/${inci_codigo}`);
    setState({
      ...initialState,
      Codigo: datos.Codigo,
      Comunidad: datosComu.Nombre,
      Estado: datos.Estado,
      Tipo: datos.Tipo,
      Actividad: datos.Actividad,
      Proveedor: datos.Proveedor,
      NombreRegistrador: datos.NombreRegistrador,
      ApellidoRegistrador: datos.ApellidoRegistrador,
      Descripcion: datos.Descripcion,
      Texto_publicar: datos.Texto_publicar,
      Publicar: datos.Publicar ? datos.Publicar : false,
      Email: datos.Email,
      Email2: datos.Email2,
      Telefono1: datos.Telefono1,
      Telefono2: datos.Telefono2,
      Notas: datos.Notas,
      Fecha_Modificacion: datos.Fecha_Modificacion,
      IdInterno: inci_key,
      Pdfs: docusInci,
      Registro: registro,
      estado_inicial: estado_inicial,
    });
  }, []);

  const Documentos = (
    <>
      {((Pdfs && Pdfs.length > 0) || !estado_inicial) && (
        <Paper>
          {Pdfs && Pdfs.length > 0 && (
            <List
              component="nav"
              dense="true"
              aria-label="main mailbox folders"
              justify="space-around"
              alignItems="center"
            >
              <h4 style={{ color: "#172983", justifyContent: "center" }}>Documentación adjunta</h4>
              {Pdfs &&
                Pdfs.map((docu) => {
                  const metaDatos = docu.metaDatos;

                  const extension = docu.id.includes(".")
                    ? docu.id.split(".").pop().toLowerCase()
                    : "";
                  const icono = obtenerIconoPorExtension(extension);

                  return (
                    <Grid>
                      <Divider />
                      <ListItem
                        key={docu.name}
                        button
                        onClick={() => {
                          window.open(docu.urlDownload);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              color: "lightblue",
                              backgroundColor: blue[500],
                            }}
                          >
                            <InsertDriveFileIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText className="listaFicheros" primary={docu.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            tooltip={diccionario.BorrarEstadoAbierta}
                            disabled={
                              state.estado_inicial ||
                              !metaDatos ||
                              metaDatos.propietario !== "gremio" ||
                              !metaDatos.quien
                            }
                            onClick={(event) => handleClickBorrarFichero(docu.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Grid>
                  );
                })}
            </List>
          )}
        </Paper>
      )}

      <Breakpoint large up>
        {!state.estado_inicial && (
          <DropzoneArea
            maxFileSize={10000000}
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}
            showPreviewsInDropzone={false}
            filesLimit={5}
            showFileNames={false}
            dropzoneText={diccionario.ArrastraFicheroCargarloVentana}
          />
        )}
      </Breakpoint>
      <Breakpoint medium down>
        {!state.estado_inicial && (
          <DropzoneArea
            maxFileSize={10000000}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            showPreviewsInDropzone={false}
            filesLimit={5}
            showFileNames={false}
            dropzoneText={diccionario.CargarFicheroAdjuntarFoto}
          />
        )}
      </Breakpoint>
    </>
  );

  return (
    <div className="container-formulario-qr">
      
      <div className="contenedor-comunidades">
        <Grid>
          <div
        style={{
          width: "100%",
          overflowX: "over",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img src="/assets/images/comunidad365.png" alt="" />
      </div>
          <div>
            {" "}
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Paper
                elevation={4}
                style={{
                  maxWidth: 800,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                }}
              >
                <Breakpoint large up>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h4 style={{ color: "#172983", marginLeft: 20 }}>
                      {diccionario.Incidencia}
                      <p style={{ color: "gray" }}> {Codigo}</p>{" "}
                    </h4>
                    <h4 style={{ color: "#172983", marginRight: 20 }}>
                      {diccionario.Proveedor}:<p style={{ color: "gray" }}> {Proveedor}</p>{" "}
                    </h4>
                  </Grid>
                </Breakpoint>
                <Breakpoint medium down>
                  <Grid>
                    <h4 style={{ color: "#172983", marginLeft: 20 }}>
                      {diccionario.Incidencia}
                      <p style={{ color: "gray" }}> {Codigo}</p>{" "}
                    </h4>
                    {diccionario.Proveedor}
                    <h4 style={{ color: "#172983", marginLeft: 20 }}>
                      {diccionario.Proveedor}
                      <p style={{ color: "gray" }}> {Proveedor}</p>{" "}
                    </h4>
                  </Grid>
                </Breakpoint>

                <div style={{ marginLeft: 20, marginRight: 20 }}>
                  {!estado_inicial && (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={state.estado_inicial}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="Texto_añadir"
                      multiline
                      label={diccionario.AnadeComentariosEnvialo}
                      name="Texto_añadir"
                      autoComplete="Texto_añadir"
                      autoFocus
                      value={Texto_añadir}
                      onChange={onChange}
                      type="text"
                      placeholder={diccionario.TextoAnadir}
                    />
                  )}

                  {!estado_inicial && <hr />}

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Estado"
                    disabled={
                      state.Texto_añadir === "" ||
                      state.Texto_añadir.trim() === "" ||
                      state.estado_inicial
                    }
                    label="Estado"
                    name="Estado"
                    autoComplete="estado"
                    onChange={onChange}
                    value={Estado}
                    select
                    placeholder={diccionario.NombreZona}
                  >
                    {listaEstados &&
                      listaEstados.map((estado) => (
                        <MenuItem key={estado.value} value={estado.value}>
                          {estado.value}
                        </MenuItem>
                      ))}
                  </TextField>
                  <Button
                    onClick={actualizar}
                    disabled={Texto_añadir === "" || Texto_añadir.trim() === "" || estado_inicial}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<UpdateIcon />}
                  >
                    {diccionario.actulizar}
                  </Button>

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nombre"
                    multiline
                    label={diccionario.DescripcionIncidencia}
                    name="nombre"
                    autoComplete="nombre"
                    value={Texto_publicar}
                    type="text"
                    placeholder={diccionario.NombreZona}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="comunidad"
                    label={diccionario.Comunidad}
                    id="descripcion"
                    autoComplete="comunidad"
                    value={Comunidad}
                    type="text"
                    placeholder={diccionario.Comunidad}
                  />
                  {ImagenUrl && (
                    <div>
                      <Typography className="imagen">
                        {diccionario.ImagenAsociada} <a href={ImagenUrl}>Pincha aquí</a>
                      </Typography>
                    </div>
                  )}
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nombre"
                    label={diccionario.UltimaModificacion}
                    name="nombre"
                    autoComplete="nombre"
                    autoFocus
                    value={Fecha_Modificacion}
                    type="text"
                    placeholder={diccionario.NombreZona}
                  />

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nombre"
                    label={diccionario.Actividad}
                    autoComplete="nombre"
                    autoFocus
                    value={Actividad}
                    type="text"
                    placeholder={diccionario.NombreZona}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nombre"
                    label={diccionario.GremioResponsable}
                    name="nombre"
                    autoComplete="nombre"
                    autoFocus
                    value={Proveedor}
                    type="text"
                    placeholder={diccionario.NombreZona}
                  />

                  {Documentos}
                </div>
              </Paper>
            </Grid>
            <Box mt={40}>{/*<Copyright />*/}</Box>
          </div>
          <Backdrop color="primary" open={Open}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Grid>
      </div>
    </div>
  );
};

export default FormularioPublicoIncidencias;
