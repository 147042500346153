import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import { MatxLoading } from "app/components";
import {
  signInWithCustomToken,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAuth,
  updateProfile,
  onAuthStateChanged,
} from "firebase/auth";
import { getDatabase, ref, onValue, get, query } from "firebase/database";
import { getNavigationByUser } from "../redux/actions/NavigationAction";
//import { SAMLAuthProvider, signInWithPopup, getRedirectResult, OAuthProvider } from "firebase/auth";
import { useStore } from "react-redux";
import { useFirebase } from "app/firebase/context";
import { useDispatch /*, useSelector*/ } from "react-redux";
import { cambiarIdioma } from "app/redux/reducers/LenguajeReducer";
import { lenguaje } from "lenguajes/index";
import { Messenger1 } from "app/views/sessions/Messenger";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  basedatos: "",
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "SET_SELECTED_DATABASE": {
      const { selectedDatabase } = action.payload;

      return {
        ...state,
        selectedDatabase,
      };
    }
    case "CREATE_UJET": {
      const { ujet } = action.payload;

      return {
        ...state,
        ujet,
      };
    }
    case "DELETE_UJET": {
      return {
        ...state,
        ujet: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

const buscaDatosUsuario = async (user) => {
  return new Promise((resolve) => {
    if (user) {
      const db = getDatabase();
      const starCountRef = ref(db, "Empleados");
      var p22 = new Promise((resolve) =>
        onValue(
          starCountRef,
          (snapshot) => {
            const data = snapshot.val();
            if (data) {
              var empleadosList = Object.keys(data).map((key) => ({
                ...data[key],
                clave: key,
              }));

              const usuario = empleadosList.find(
                (usu) => !usu.Borrado && usu.Key === user.uid
              );
              user["role"] = "GUEST";
              user["name"] = "SIN NOMBRE";

              if (usuario) {
                user["role"] = usuario.Perfil;
                user["name"] = usuario.Nombre + " " + usuario.Apellido;
                user["id"] = usuario.id;
              }
            }

            resolve(user);
          },
          {
            onlyOnce: true,
          }
        )
      );
      var p1 = new Promise((resolve) =>
        onValue(
          ref(db, "Estructura"),
          (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const estructuras = Object.keys(data).map((key) => ({
                ...data[key],
                clave: key,
              }));

              resolve(estructuras);
            } else {
              resolve([]);
            }
          },
          {
            onlyOnce: true,
          }
        )
      );

      Promise.all([p1, p22]).then((values) => {
        if (values[0].length > 0) {
          values[1]["Gerente"] = true;
          values[1]["Estructuras"] = values[0].filter(
            (estru) => estru.Id_gerente === values[1].id
          );
        }
        resolve(values[1]);
      });
      // ...
    } else {
      resolve(null);
      // User is signed out
      // ...
    }
  });
};
const estadoAutorizacion = async () => {
  const auth = getAuth();

  return new Promise((resolve) =>
    onAuthStateChanged(auth, (user) => {
      if (user) {
        buscaDatosUsuario(user).then((usuario) => {
          resolve(usuario);
        });
      } else {
        resolve(null);
        // User is signed out
        // ...
      }
    })
  );
};
export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const store = useStore();
  const firebase = useFirebase();
  const dispatch2 = useDispatch();

  const login = async (email, password, basedatos) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    console.log("respuesta",response);
    // Login con token
    //const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwMTc2MTgwNCwiZXhwIjoxNzAxNzY1NDA0LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1kd3l0ZUBjb25lY3RhLWMzNjUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay1kd3l0ZUBjb25lY3RhLWMzNjUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJxV1hHd3dOQTd3V0dsVmY1OTRVUGZtSTh3OTUyIn0.BBThqsvA2R1JgDPeEE6aIIcNZbunRsSYVJ6IUxHK0CmWVfIZdQ5NbxY_w-XOhnyMJ0C5IorMmpFGzuYLreyxF_-c3ktZvjZ3cDO58fnFEF0xObWu60Y5mXYV0fUMGbrT8Ug7vW8pG--m1OFLik2AeDQCTiPRXizsRXuRFyjDB1g1spFX8F1L4wz97t0bGibXFJem60fcrpSVhQtaR9GoiRIeQQ-vpm1WFyDNuv0ayM23-4VCC6Ahbt_-t_OGwoE7d4CmUJcCdmtvpDrvBihzk1lsqomWdWxzYu057UhxlAVdr3O0fcIETusjV2o60QmnEE-a-u4rI0KeJ5we2nC7GA';
    //const response = await signInWithCustomToken(auth,token);
    var { user } = response;

    const customClaims = await user.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims;
    });
    const connectionInfo = customClaims.connectionInfo ?? null;

    const lengu = store.getState().lenguajes;
    const idiomaSeleccionadoState = lengu.lenguaje;
    // Fuerzo el cambio de idioma al actual para que se regeneren las cookies de idioma
    dispatch2(
      cambiarIdioma({
        diccionario: lenguaje[idiomaSeleccionadoState].diccionario,
        lenguaje: idiomaSeleccionadoState,
        traduccionTabla: lenguaje[idiomaSeleccionadoState].traduccionTabla,
      })
    );

    if (connectionInfo != null) {

      // Recupero la lista de bases de datos, una por despacho
      const arrDatabases = connectionInfo;
      // Me conecto por defecto a la primera de la lista
      const selectedDatabase = 0;
      const currentDatabase = arrDatabases[selectedDatabase];
      // Si hay base de datos me conecto
      if (currentDatabase.url) {
        firebase.changeDatabase(currentDatabase.url);
        const id = currentDatabase.url.split("conecta-c365-")[1].split(".")[0];
        firebase.changeStorage(`gs://conecta-c365-${id}`);
        // Recupero los datos del despacho
        const refDespacho = firebase.getReferencia("Despacho");
        const snapshot = await get(query(refDespacho));
        const objDespacho = snapshot.val();
        user.despacho = objDespacho;
        const refUser = firebase.getReferencia(
          `Usuarios/${customClaims.user_id}`
        );
        const snapshotUser = await get(query(refUser));
        const objUser = snapshotUser.val();
        user.datosUsuarioBD = objUser;
        user.currentDB = currentDatabase.url;
        user.allDatabases = arrDatabases;
        user.selectedDatabase = selectedDatabase;
        localStorage.setItem("selectedDatabase", selectedDatabase);
        //const userData = await get(ref(firebase.dbAdmin,`aaff/${customClaims.user_id}`));

        const urlCompleta = user.allDatabases[user.selectedDatabase];
        //try {
          const ujet = await Messenger1(urlCompleta.url, firebase, objUser);
          dispatch({
            type: "CREATE_UJET",
            payload: {
              ujet: ujet, // Parse as integer
            },
          });
        //} catch (error) {
        //  console.log("error",error);
        //}
      }
      // Dispatch action to set selectedDatabase in the store
      dispatch({
        type: "SET_SELECTED_DATABASE",
        payload: {
          selectedDatabase: parseInt(selectedDatabase, 10), // Parse as integer
        },
      });

      //Lancamos la  ayuda tupo Messenger
    }


    if (user) {

      const usuario = await buscaDatosUsuario(user);

      if (usuario) {
        //     user['role']=usuario.Perfil
        //   user['name']=usuario.Nombre+' '+ usuario.Apellido
        user = { ...usuario };
        //          actualizaUsuario(usuario)

        const melon = store.getState().lenguajes;
        const diccionario = melon.diccionario;

        getNavigationByUser(store.dispatch, user, diccionario);

        const accessToken = user["accessToken"];

        setSession(accessToken);
      } else {
        user = null;
      }
    } else {
      user = null;
    }

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        basedatos,
      },
    });

  };
  const loginToken = async (token, basedatos) => {
    const response = await signInWithCustomToken(auth, token);
    var { user } = response;

    const customClaims = await user.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims;
    });
    const connectionInfo = customClaims.connectionInfo ?? null;

    const lengu = store.getState().lenguajes;
    const idiomaSeleccionadoState = lengu.lenguaje;
    // Fuerzo el cambio de idioma al actual para que se regeneren las cookies de idioma
    dispatch2(
      cambiarIdioma({
        diccionario: lenguaje[idiomaSeleccionadoState].diccionario,
        lenguaje: idiomaSeleccionadoState,
        traduccionTabla: lenguaje[idiomaSeleccionadoState].traduccionTabla,
      })
    );

    if (connectionInfo != null) {
      // Recupero la lista de bases de datos, una por despacho
      const arrDatabases = connectionInfo;
      // Me conecto por defecto a la primera de la lista
      const selectedDatabase = 0;
      const currentDatabase = arrDatabases[selectedDatabase];
      // Si hay base de datos me conecto
      if (currentDatabase.url) {
        firebase.changeDatabase(currentDatabase.url);
        const id = currentDatabase.url.split("conecta-c365-")[1].split(".")[0];
        firebase.changeStorage(`gs://conecta-c365-${id}`);
        // Recupero los datos del despacho
        const refDespacho = firebase.getReferencia("Despacho");
        const snapshot = await get(query(refDespacho));
        const objDespacho = snapshot.val();
        user.despacho = objDespacho;
        const refUser = firebase.getReferencia(
          `Usuarios/${customClaims.user_id}`
        );
        const snapshotUser = await get(query(refUser));
        const objUser = snapshotUser.val();
        user.datosUsuarioBD = objUser;
        user.currentDB = currentDatabase.url;
        user.allDatabases = arrDatabases;
        user.selectedDatabase = selectedDatabase;
        localStorage.setItem("selectedDatabase", selectedDatabase);
        //const userData = await get(ref(firebase.dbAdmin,`aaff/${customClaims.user_id}`));
      }
      // Dispatch action to set selectedDatabase in the store
      dispatch({
        type: "SET_SELECTED_DATABASE",
        payload: {
          selectedDatabase: parseInt(selectedDatabase, 10), // Parse as integer
        },
      });

      const urlCompleta = user.allDatabases[user.selectedDatabase];
      //Lancamos la  ayuda tupo Messenger
      Messenger1(urlCompleta.url, firebase, customClaims);
    }
    if (user) {
      const usuario = await buscaDatosUsuario(user);

      if (usuario) {
        //     user['role']=usuario.Perfil
        //   user['name']=usuario.Nombre+' '+ usuario.Apellido
        user = { ...usuario };
        //          actualizaUsuario(usuario)

        const melon = store.getState().lenguajes;
        const diccionario = melon.diccionario;

        getNavigationByUser(store.dispatch, user, diccionario);

        const accessToken = user["accessToken"];

        setSession(accessToken);
      } else {
        user = null;
      }
    } else {
      user = null;
    }

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        basedatos,
      },
    });
  };

  const register = async (email, username, password) => {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const { user } = response;
    const accessToken = user["accessToken"];

    setSession(accessToken);

    updateProfile(auth.currentUser, {
      displayName: "Pedro García",
      photoURL: "https://example.com/jane-q-user/profile.jpg",
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    
    await signOut(auth);

    setSession(null);
    dispatch({ type: "LOGOUT" });

    // Eliminar el Ujet
    dispatch({ type: "DELETE_UJET" });

    // Eliminar el botón del Chatbot
    const script2 = document.getElementById("df-messenger");
    if (script2 != null) {
      document.body.removeChild(script2);
    }
    const estilo1 = document.getElementById("estilo1");
    if (estilo1 != null) {
      document.body.removeChild(estilo1);
    }
  };

  async function entra(accessToken) {
    setSession(accessToken);
    //  const response = await axios.get('/api/auth/profile')
    const user = await estadoAutorizacion();
    //      user['role']='SA'
    //    user['name']=user.displayName
    const melon = store.getState().lenguajes;
    const diccionario = melon.diccionario;

    const customClaims = await user.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims;
    });
    const connectionInfo = customClaims.connectionInfo ?? null;

    if (connectionInfo != null) {
      // Recupero la lista de bases de datos, una por despacho
      const arrDatabases = connectionInfo;
      // Me conecto por defecto a la primera de la lista
      const selectedDatabase = localStorage.getItem("selectedDatabase");
      const currentDatabase = arrDatabases[selectedDatabase];
      // Si hay base de datos me conecto
      if (currentDatabase.url) {
        firebase.changeDatabase(currentDatabase.url);
        const id = currentDatabase.url.split("conecta-c365-")[1].split(".")[0];
        firebase.changeStorage(`gs://conecta-c365-${id}`);
        // Recupero los datos del despacho
        const refDespacho = firebase.getReferencia("Despacho");
        const snapshot = await get(query(refDespacho));
        const objDespacho = snapshot.val();
        user.despacho = objDespacho;
        const refUser = firebase.getReferencia(
          `Usuarios/${customClaims.user_id}`
        );
        const snapshotUser = await get(query(refUser));
        const objUser = snapshotUser.val();
        user.datosUsuarioBD = objUser;
        user.currentDB = currentDatabase.url;
        user.allDatabases = arrDatabases;
        user.selectedDatabase = selectedDatabase;
        localStorage.setItem("selectedDatabase", selectedDatabase);
        //const userData = await get(ref(firebase.dbAdmin,`aaff/${customClaims.user_id}`));
        const urlCompleta = user.allDatabases[user.selectedDatabase];
        const ujet = await Messenger1(urlCompleta.url, firebase, objUser);
        dispatch({
          type: "CREATE_UJET",
          payload: {
            ujet: ujet, // Parse as integer
          },
        });
      }

      // Dispatch action to set selectedDatabase in the store
      dispatch({
        type: "SET_SELECTED_DATABASE",
        payload: {
          selectedDatabase: parseInt(selectedDatabase, 10), // Parse as integer
        },
      });
      const urlCompleta = user.allDatabases[user.selectedDatabase];
      //Lancamos la  ayuda tupo Messenger
    } else {
      console.log("PERDIDOS LOS CUSTOM CLAIMS", connectionInfo);
    }

    getNavigationByUser(store.dispatch, user, diccionario);

    return dispatch({
      type: "INIT",
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  }
  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          entra(accessToken);
        } else {
          /*
                                        const provider = new OAuthProvider('microsoft.com');
                    //                    const provider = new SAMLAuthProvider('saml.prueba-sal');
                          
                                        const auth = getAuth();
                                        provider.setCustomParameters({
                                            // Force re-consent.
                                       //     prompt: 'consent',
                                            // Target specific email with login hint.
                                          //  client_id:'e1af9b25-1c73-44ff-84dc-0f2e8e0ae5b7',
                                            login_hint: 'rondon.grijalba.pablo@gmail.com'
                                          });
                                         const res1= await signInWithPopup(auth, provider)
                                        if(res1){
                    
                    
                                           entra(res1.user.accessToken)}
                                        else{
                                            */

          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
              basedatos: null,
            },
          });

          //}
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
            basedatos: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        loginToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
